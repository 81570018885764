import React from 'react'
import { motion as m } from "framer-motion";

import BackButton from '../../../global/modules/BackButton';

import data from "../../../../data/global/data";

const Stats = () => {
    let page_name = window.location.pathname;
    let page_name_reduced = page_name.replace("/projects/", "").replace("/status", "");
    let page_name_reduced_capital = page_name_reduced.charAt(0).toUpperCase() + (page_name_reduced.replace(page_name_reduced.charAt(0), ""));
    return (
        <>
            <BackButton />
            <m.div className="stats" initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                transition={{ duration: data.pageTransitions.duration, ease: "easeOut" }}
                exit={{ opacity: 1 }}>
                <div className="stats__wrapper">
                    <div className="wrapper__title">
                        <h1 className='title__h1'>{page_name_reduced_capital}</h1>
                    </div>
                    <div className="wrapper__stats">
                        <div className="stats__card">
                            <div className="card__title">
                                <div className="title__h2">
                                    Status
                                </div>
                            </div>
                            <div className="card__data">
                                <div className="data__text">
                                    <div className={`item ${page_name_reduced + "_percentage"}`}>
                                        { }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    )
}

export default Stats