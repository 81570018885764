import { BrowserRouter as Router } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from "react-device-detect"

import AnimatedRoutes from "./components/global/AnimatedRoutes"

import "./styles/global/uni.scss"


const App = () => {
  let uid = uuidv4();
  try {
    console.log("Working!");
    localStorage.setItem("uuid", uid);
  } catch (err) {
    console.log(err);
  }
  if (isMobile) {
    alert("isMobile");
  }
  return (
    <Router>
        <AnimatedRoutes />
    </Router>
  );
}

export default App;
