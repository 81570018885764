const title = [
  {
    "h1": [
      "Contact Info"
    ],
    "h2": [
      "_"
    ]
  }
]
const info = [
  {text: "darksidewebowner@gmail.com",link:"|>darksidewebowner@gmail.com",working:1},
  {text: "@darksideweb",link:"replace_with_social_url",working:0}
]
export { title, info }