import React from 'react'
import $ from "jquery"

const HomeButton = () => {
    $(document).ready(function(){
        $(".home-button").unbind().click(function(){
            window.location.pathname = "/";
        })
    })
  return (
    <button className="home-button">Home</button>
  )
}

export default HomeButton