import React, { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from "react-router-dom"
import { AnimatePresence } from "framer-motion"
import { onAuthStateChanged } from 'firebase/auth'

import { Layout, NoPage } from "./Global";

import Home from "../pages/home/Home";
import Navigate from '../pages/navigate/Navigate';
import Projects from '../pages/projects/Projects';
import Contact from '../pages/contact/Contact';
import StatsContainer from '../pages/projects/StatsContainer';
import Register from '../firebase/user/Register'
import VerifyEmail from '../firebase/user/VerifyEmail';
import Login from '../firebase/user/Login'
import Profile from "../firebase/user/Profile"
import { AuthProvider } from '../firebase/AuthContext'
import PrivateRoute from '../firebase/user/PrivateRoute'
import { auth } from '../firebase/firebase'

import { recentProjectsData } from '../../data/pages/projects/data';

const AnimatedRoutes = () => {
  const location = useLocation();

  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])
  return (
    <AnimatePresence initial={false}>
      <AuthProvider value={{ currentUser, timeActive, setTimeActive }}>
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Layout />}>
            <Route index element={<Home />} key={location.pathname} />
            <Route path="navigate" element={<Navigate />} key={location.pathname} />
            <Route path="projects" key={location.pathname}>
              <Route index element={<Projects />} />
              {recentProjectsData.map((data, key) => {
                return (
                  <Route path={data.page} key={key}>
                    <Route index element={<></>} />
                    <Route path="status" element={<StatsContainer />} />
                  </Route>
                )
              })}
            </Route>
            <Route path="contact" element={<Contact />} />
            <Route path="profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="verify-email" element={<VerifyEmail />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </AuthProvider>
    </AnimatePresence>
  )
}

export default AnimatedRoutes