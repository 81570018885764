import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import $ from "jquery"
import { motion as m } from 'framer-motion';

import data from '../../data/global/data';

const Layout = () => {
    let containerStyle = { overflow: "hidden" };
    let secondaryDuration = data.pageTransitions.duration * 1000;
    $(document).ready(function () {
        // $(".container").css({ "background-image": `url(${backgroundImage})`, "background-size": "cover", "background-position": "center", "background-repeat": "no-repeat" })
        setTimeout(function(){
            $(".container").css({"overflow-y": "auto"});
        }, secondaryDuration);
        if (window.location.pathname.length <= 1) {
            // $(".container").css({ "background-image": `url(${backgroundImage})`, "background-size": "cover", "background-position": "center", "background-repeat": "no-repeat" })
        } else {
        }
    })
    return (
        <>
            {/* <Header /> */}
            <div className="container" style={containerStyle}>
                <Outlet />
            </div>
        </>
    )
}
const NoPage = () => {
    let location = window.location.pathname;
    $(document).ready(function () {
        console.log("The page named: " + location + " does not exist. This may be a client issue, or an issue on our end. The issue will be resolved as soon as possible.");
    })
    return (
        <>
            <m.div className="page-container flex_center flex_column" initial={{ y: "100%" }} animate={{ y: "0%" }}
                transition={{ duration: data.pageTransitions.duration, ease: "easeOut" }} exit={{ y: "-100%" }}>
                <h1 className='color_white'>
                    There is no "{location}" on this site.
                </h1>
                <Link to="/" className='nav-link'>Home</Link>
            </m.div>
        </>
    )
}

export { Layout, NoPage }