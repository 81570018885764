import { useAuthValue } from '../AuthContext'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { motion as m } from "framer-motion"

import BackButton from "../../global/modules/BackButton"
import HomeButton from '../../global/modules/HomeButton'

import data from '../../../data/global/data';

function Profile() {
  const { currentUser } = useAuthValue()

  return (

    <>
      <BackButton />
      <HomeButton />
      <m.div className='center' initial={{ y: "100%" }} animate={{ y: "0%" }}
    transition={{ duration: data.pageTransitions.duration, ease: "easeOut" }} exit={{opacity: 1}}>
        <div className='profile'>
          <h1>Profile</h1>
          <div className="info">
            <p><strong>Email: </strong>{currentUser?.email}</p>
            <p>
              <strong>Email verified: </strong>
              {`${currentUser?.emailVerified}`}
            </p>
            <span onClick={() => signOut(auth)}>Sign Out</span>
          </div>
        </div>
      </m.div></>
  )
}

export default Profile
