const currentDate = new Date();
const recentProjectsData = [
    {
        text: "Virtuous Roleplay",
        link: "/_",
        page: "virtuousroleplay",
        for: "",
        "status": [{
            "card": [{ description: "Final", color: "green" }],
            "page": [{ percentage: "90%", language: "Javascript", otherLanguages: ["CSS", "JSON"], compilers: ["React JS", "SASS"], editorUsed: "Visual Studio Code" }]
        }],
        date: [2, 12, 2023]
    },
    {
        text: "Grasso Tech IT Dept.",
        link: "https://www.itdepartment.cf",
        page: "grassotechitdepartment",
        for: "Grasso Tech",
        "status": [{
            "card": [{ description: "In Development", color: "yellow" }],
            "page": [{ percentage: "90%", language: "Javascript", otherLanguages: ["CSS", "JSON"], compilers: ["React JS", "SASS"], editorUsed: "Visual Studio Code" }]
        }],
        date: [currentDate.getMonth() + 1, currentDate.getDate(), currentDate.getFullYear()]
    },
    {
        text: "Grasso Info",
        link: "/_",
        page: "grassoinfo",
        for: "Grasso Tech",
        "status": [{
            "card": [{ description: "Not Started", color: "red" }],
            "page": [{ percentage: "90%", language: "Javascript", otherLanguages: ["CSS", "JSON"], compilers: ["React JS", "SASS"], editorUsed: "Visual Studio Code" }]
        }], date: "___"
    },
    {
        text: "Digital Media Notes",
        link: "https://www.dmadnotes.tk/",
        page: "dmadnotes",
        for: "Grasso Tech",
        "status": [{
            "card": [{ description: "Working", color: "green" }],
            "page": [{ percentage: "90%", language: "Javascript", otherLanguages: ["CSS", "JSON"], compilers: ["React JS", "SASS"], editorUsed: "Visual Studio Code" }]
        }], date: "___"
    },
    {
        text: "Grasso Tech Shops",
        link: "https://helpful-kheer-3b895b.netlify.app/",
        page: "grassotechshops",
        for: "Grasso Tech",
        "status": [{
            "card": [{ description: "In Development", color: "red" }],
            "page": [{ percentage: "90%", language: "Javascript", otherLanguages: ["CSS", "JSON"], compilers: ["React JS", "SASS"], editorUsed: "Visual Studio Code" }]
        }], date: "___"
    }
];
const intro = [
    {
        "title": [
            {
                text: "Our Projects"
            }
        ],
        "description": [
            {
                text: "Listed below are all of our projects that we have developed, and received permission to show in our portfolio."
            }
        ]
    }
];
export { recentProjectsData, intro }