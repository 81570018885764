import React from 'react'
import { Link } from "react-router-dom"
import { motion as m } from 'framer-motion'

import data from '../../../data/global/data';
import { navigationLinks } from "../../../data/header/data";

const Navigate = () => {
  return (
    <m.div className="navigate" initial={{ x: "100%" }} animate={{ x: "0%" }}
    transition={{ duration: data.pageTransitions.duration, ease: "easeOut" }} exit={{x:"0%"}}>
        <div className="navigate-wrapper">
            <div className="wrapper__links">
                {navigationLinks.map((data,key)=>{return(
                  <Link key={key} to={data.link} className='links__link'>
                      <p>
                        {data.text}
                      </p>
                      <span className="material-symbols-outlined icon">
                        {data.icon}
                      </span>
                      <span className="arrow">
                        <span className="material-symbols-outlined">
                          expand_less
                        </span>
                      </span>
                  </Link>
                )})}
                <Link className="links__login" to="/profile"><p>Profile</p></Link>
            </div>
        </div>
    </m.div>
  )
}

export default Navigate