import React from "react";
import { motion as m } from "framer-motion";
import $ from "jquery"

import ProjectsList from "./modules/ProjectsList"
import BackButton from "../../global/modules/BackButton";

import data from "../../../data/global/data";
import { intro } from "../../../data/pages/projects/data"

import logo from "../../../assets/images/moon-isolated-on-transparent-free-png.webp"

function Projects() {
  let cSpacing = 0;
  $(document).ready(function () {
    var $rows = $('#projects tr');
    $('#search').keyup(function () {
      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $rows.show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });
  })
  return (
    <>
      <BackButton />
      <m.div
        className="projects"
        initial={{ x: "100%" }}
        animate={{ x: "0%" }}
        transition={{ duration: data.pageTransitions.duration, ease: "easeOut" }}
        exit={{ opacity: 1 }}
      >
        <div className="projects__wrapper">
          <div className="wrapper__section wrapper__section-intro">
            <img src={logo} alt="" />
          </div>
          <div className="wrapper__section wrapper__section-title">
            <div className="section__text">
              <h1>{intro[0].title[0].text}</h1>
              <p>
                {intro[0].description[0].text}
              </p>
            </div>
          </div>
          <div className="wrapper__section wrapper__section-projects">
            <input type="text" id="search" placeholder="Search for names.." />
            <table className="section__table" id="projects" cellSpacing={cSpacing}>
              <tbody>
                <ProjectsList />
              </tbody>
            </table>
          </div>
        </div>
      </m.div>
    </>
  );
}

export default Projects;
