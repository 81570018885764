import React from 'react'
import $ from "jquery";
import { motion as m } from "framer-motion"
import { useNavigate } from "react-router-dom"

import data from '../../../data/global/data';

const Home = () => {
    const navigate = useNavigate();
    $(document).ready(function() {
        try {
            console.clear().log("All good here!");
        } catch(e) {
            console.log(e);
        }

        $(".continue__button").unbind().click(function(){
            navigate("/navigate")
        })
    });
  return (
    <m.div className="home" initial={{ y: "100%" }} animate={{ y: "0%" }}
    transition={{ duration: data.pageTransitions.duration, ease: "easeOut" }} exit={{opacity: 1}}>
        <div className="home__wrapper">
            <div className="wrapper__title">
                <h1 className="title__h1">
                    Welcome to
                </h1>
                <h2 className="title__h2">
                    Dark Side Web
                </h2>
            </div>
            <div className="wrapper__continue">
                <button className="continue__button center-con">
                    <div class="round">
                        <div id="cta">
                            <span class="arrow primera next "></span>
                            <span class="arrow segunda next "></span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </m.div>
  )
}

export default Home