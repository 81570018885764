import React from 'react'
import $ from "jquery"

const BackButton = () => {
    $(document).ready(function(){
        $(".back-button").unbind().click(function(){
            window.history.back();
        })
    })
  return (
    <button className="back-button">Back</button>
  )
}

export default BackButton