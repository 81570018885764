import React from 'react'
import { Link } from 'react-router-dom'

import { recentProjectsData } from "../../../../data/pages/projects/data"

const ProjectsList = () => {
  return (
    <>
      {recentProjectsData.map((data, key) => {
        return (
          <tr className="table__project" key={key}>
            <td><Link className="project__link" to={data.link}>{data.text}</Link></td>
            <td><div className={`status ${data.status[0].card[0].color}`}></div><Link id="status" to={data.page + "/status"}>{data.status[0].card[0].description}</Link></td>
            <td>For: {data.for}</td>
            <td>{data.date[0] + "/" + data.date[1] + "/" + data.date[2]}</td>
          </tr>
        )
      })}
    </>
  )
}

export default ProjectsList