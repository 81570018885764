import React from 'react'
import { motion as m } from "framer-motion"
import $ from "jquery"

import BackButton from "../../global/modules/BackButton"

import data from '../../../data/global/data';
import { title, info } from "../../../data/pages/contact/data"

const Contact = () => {
    const runLink = (link) => {
        if (link.includes("|>")) {
            return "mailto:" + link
        } else {
            return link
        }
    }
    const checkWorking = (isWorking) => {
        if (isWorking === 1) {
            return ('working')
        } else if (isWorking === 0) {
            return ('notworking')
        }
    }
    $(document).ready(function () {
        if ($(".title__h2").html() === "_") {
            $(".title__h2").css({ display: "none" });
        }
        else {
            $(".title__h2").css({ display: "block" });
        }
    })
    return (
        <>
            <BackButton />
            <m.div className="contact" initial={{ y: "100%" }} animate={{ y: "0%" }}
                transition={{ duration: data.pageTransitions.duration, ease: "easeOut" }} exit={{ opacity: 1 }}>
                <div className="contact__wrapper">
                    <div className="wrapper__title">
                        <h1 className="title__h1">{title[0].h1}</h1>
                        <h2 className="title__h2">{title[0].h2}</h2>
                    </div>
                    <div className="wrapper__contact-info">
                        {info.map((data, key) => {
                            return (
                                <div className={`contact-info__section ${checkWorking(data.working)}`} key={key}>
                                    <span className="section__text">
                                        <a href={runLink(data.link)}>{data.text}</a>
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </m.div>
        </>
    )
}

export default Contact