import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyDRFr-_tu8JKx4wXPVAVd7CnfcYXor--04",
    authDomain: "darksideweb-21996.firebaseapp.com",
    databaseURL: "https://darksideweb-default-rtdb.firebaseio.com",
    projectId: "darksideweb",
    storageBucket: "darksideweb.appspot.com",
    messagingSenderId: "234478279167",
    appId: "1:234478279167:web:9ced2bc00ecbc53816efca"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

export { auth }
